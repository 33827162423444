import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { format, parse, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { FaTimes, FaUserFriends, FaSave, FaTimesCircle } from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import formatDocument from '~/util/formatDocument';

// import { Input, Select } from '~/components/Form';
import { TableLoading } from '~/components/Table';

import {
  Container,
  Header,
  Controls,
  DetailsContainer,
} from '~/styles/components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Content, EmployeeForm } from './styles';

const Edit = ({ match }) => {
  const { company } = useAuth();
  const formRef = useRef(null);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState({});
  const [clientsOptions, setClientsOptions] = useState([]);

  const loadClients = useCallback(async () => {
    if (company) {
      // eslint-disable-next-line no-console
      console.log(clientsOptions);
      try {
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            active: true,
            type: 1,
          },
        });

        const options = response.data.map(item => ({
          label: item.name,
          value: item.id,
        }));

        setClientsOptions(options);
      } catch {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [company]);

  const loadEmployee = useCallback(async () => {
    if (company) {
      try {
        setLoading(true);

        const response = await api.get(`/employees/${match.params.id}`);

        if (response.data) {
          const formattedEmployee = {
            ...response.data,
            client_name: response.data.client.name,
            client_nickname: response.data.client.nickname,
            admission_date: response.data.admission_date
              ? format(parseISO(response.data.admission_date), 'dd/MM/yyyy')
              : '',
            resignation_date: response.data.resignation_date
              ? format(parseISO(response.data.resignation_date), 'dd/MM/yyyy')
              : '',
            attest_date: response.data.attest_date
              ? format(parseISO(response.data.attest_date), 'dd/MM/yyyy')
              : '',
            first_ctto_date: response.data.first_ctto_date
              ? format(parseISO(response.data.first_ctto_date), 'dd/MM/yyyy')
              : '',
            second_ctto_date: response.data.second_ctto_date
              ? format(parseISO(response.data.second_ctto_date), 'dd/MM/yyyy')
              : '',
            cpf: response.data.cpf ? formatDocument(1, response.data.cpf) : '',
          };

          setEmployee(formattedEmployee);
        } else {
          toast.warn('Nenhum funcionário foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch {
        toast.error('Falha ao buscar funcionários.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [company, match.params.id]);

  useEffect(() => {
    loadClients();
    loadEmployee();
  }, [loadClients, loadEmployee]);

  const handleSave = useCallback(async () => {
    try {
      const formattedEmployee = {
        ...employee,
        admission_date: employee.admission_date
          ? parse(
              employee.admission_date,
              'dd/MM/yyyy',
              new Date()
            ).toISOString()
          : null,
        resignation_date: employee.resignation_date
          ? parse(
              employee.resignation_date,
              'dd/MM/yyyy',
              new Date()
            ).toISOString()
          : null,
        attest_date: employee.attest_date
          ? parse(employee.attest_date, 'dd/MM/yyyy', new Date()).toISOString()
          : null,
        first_ctto_date: employee.first_ctto_date
          ? parse(
              employee.first_ctto_date,
              'dd/MM/yyyy',
              new Date()
            ).toISOString()
          : null,
        second_ctto_date: employee.second_ctto_date
          ? parse(
              employee.second_ctto_date,
              'dd/MM/yyyy',
              new Date()
            ).toISOString()
          : null,
      };

      await api.put(`/employees/${match.params.id}`, formattedEmployee);

      toast.success('Funcionário alterado com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      history.push('/teste');
    } catch (error) {
      toast.error('Falha ao salvar funcionário.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [employee, history, match.params.id]);

  const handleCancel = useCallback(() => {
    history.push('/teste');
  }, [history]);

  const handleChange = e => {
    const { name, value } = e.target;
    setEmployee(prevEmployee => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  return (
    <Container>
      <Header>
        <div>
          <FaUserFriends />
          <h1>Funcionários</h1>
        </div>

        <Link to="/">
          <FaTimes />
        </Link>
      </Header>
      <Controls>
        <button type="button" onClick={handleSave}>
          <FaSave size={15} color="#44546a" />
          <span>Salvar</span>
        </button>
        <button type="button" onClick={handleCancel}>
          <FaTimesCircle />
          <span>Cancelar</span>
        </button>
      </Controls>
      {loading ? (
        <TableLoading />
      ) : (
        <Content className="content">
          <DetailsContainer>
            {employee && (
              <EmployeeForm ref={formRef} onSubmit={handleSave}>
                <h4>Funcionários</h4>
                <section>
                  <div className="cpf">
                    <label>CPF</label>
                    <input
                      name="cpf"
                      value={employee?.cpf || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="name">
                    <label>Nome</label>
                    <input
                      name="name"
                      value={employee?.name || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="client">
                    <label>Cliente</label>
                    <input
                      name="client_name"
                      value={employee?.client_name || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="situation">
                    <label>Situação</label>
                    <input
                      name="situation"
                      value={
                        employee?.situation === 0 ? 'Admitido' : 'Demitido'
                      }
                      onChange={handleChange}
                    />
                  </div>
                </section>
                <section>
                  <div className="date">
                    <label>Admissão</label>
                    <input
                      name="admission_date"
                      value={employee?.admission_date || 'Sem data'}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="date">
                    <label>Demissão</label>
                    <input
                      name="resignation_date"
                      value={employee?.resignation_date || 'Não foi demitido'}
                      readOnly
                    />
                  </div>
                  <div className="date">
                    <label>Data de atestado</label>
                    <input
                      name="attest_date"
                      value={employee?.attest_date || 'Sem data'}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="date">
                    <label>Fim Etapa 1 Ctto</label>
                    <input
                      name="first_ctto_date"
                      value={employee?.first_ctto_date || 'Sem data'}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="date">
                    <label>Fim Etapa 2 Ctto</label>
                    <input
                      name="second_ctto_date"
                      value={employee?.second_ctto_date || 'Sem data'}
                      onChange={handleChange}
                    />
                  </div>
                </section>
                {/* <Select
                label="Cliente2"
                name="client2"
                className="client2"
                options={clientsOptions}
                defaultValue={defaultClientOption}
                placeholder="Selecione um cliente"
              /> */}
              </EmployeeForm>
            )}
          </DetailsContainer>
        </Content>
      )}
    </Container>
  );
};

export default Edit;
